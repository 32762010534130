<template>
    <div>
        <div>
            <a href="https://myr8tst.richmond.ca/my-voice/history">Case History</a>
        </div>
        <div>
            <a href="https://myr8tst.richmond.ca/my-voice">My Voice Service Request Cards</a>
        </div>
        <div>
            <a href="https://myr8tst.richmond.ca/my-home">My Home</a>
        </div>
        <div>
            <a href="https://myr8tst.richmond.ca/my-community">My Community</a>
        </div>
        <div>
            <a href="https://myr8tst.richmond.ca/dashboard">Dashboard</a>
        </div>
        <div>
            <a href="https://myr8tst.richmond.ca/my-community/rec-card">Rec Card</a>
        </div>
        <div>
            <a href="https://sso7tst.richmond.ca/registered">Account Registration Done</a>
        </div>
        <div>
            <a href="https://sso7tst.richmond.ca/resetdone">Password Reset Done</a>
        </div>
        <div>
            <a href="https://login7tst.richmond.ca/link/registered">login7tst Account Registration Done</a>
        </div>
        <div>
            <a href="https://login7tst.richmond.ca/link/resetdone">login7tst Password Reset Done</a>
        </div>
    </div>
</template>

<script>

// Visit this page using Vue.js hash mode URL. Ex. http://localhost:8083/#/testuniversal
export default {
    name: 'TestUniversal',
    data() {
        return {}
    },
    mounted() {
    }
};

</script>